<template>
    <div>

        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12 ms-sm-auto col-lg-12">
                    <div class="container-fluid">
                        <div class="row" v-if="tiersOn">
                            <div class="col-md-12">
                                <h3>Tier Renewal</h3>
                                <p>Renew <strong>{{itemData.tName}}</strong></p>
                            </div>
                            <div class="col-md-12">
                                <span class="text-main-gradient-secondary">Billed annually</span>
                            </div>
                            <!-- <div class="col-md-8">
                                <p>Would you like to add an <strong class="text-main-gradient-secondary">annual hosting
                                        plan</strong> to your tier?</p>
                                <vs-switch color="#214455" style="background: #1a5cff !important" v-model="billed">
                                    <template #off>
                                        No
                                    </template>
                                    <template #on>
                                        Yes add a $3000 USD/year hosting
                                    </template>
                                </vs-switch>
                            </div> -->
                        </div>
                        <div class="row" v-if="tiersOn">
                            <div class="col-md-12 d-flex justify-content-center mt-3" v-for="(item, index) in tiers"
                                :key="index" v-if="item.package_id === itemData.realTierId">
                                <div class="card main-card h-100">
                                    <div class="card-body">

                                        <p class="text-main-gradient"> {{item.package_name}}</p>
                                        <template v-if="billed">
                                            <h3>${{item.cost_yearly}} <small class="text-muted">/yearly</small></h3>
                                        </template>
                                        <template v-else>
                                            <h3>${{item.cost_monthly}} <small class="text-muted">/monthly</small></h3>
                                        </template>
                                        <hr>
                                        <p>
                                            <strong>Features</strong> <br> <br>
                                            <span class="smallText mb-1 d-flex align-items-center justify-content-left"
                                                v-for="(item2, index2) in item.features" :key="index2">
                                                <div class="circle me-1"></div> {{item2.feature_name}}
                                            </span>
                                        </p>
                                    </div>
                                    <div class="card-footer">

                                        <template v-if="billed">
                                            <vs-button
                                                @click="selectTier(item.package_id, item.cost_yearly, item.package_name, 'yearly')"
                                                block>Continue</vs-button>
                                        </template>
                                        <template v-else>
                                            <vs-button
                                                @click="selectTier(item.package_id, item.cost_yearly, item.package_name, 'yearly')"
                                                block>Continue</vs-button>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-show="selectedOn">
                            <div class="col-md-12">
                                <h4>Checkout</h4>
                                <p>Please check that the data of the tier you have chosen is correct.</p>
                                <div class="card main-card">
                                    <div class="card-body">
                                        <p>
                                            <strong>Tier:</strong> <span
                                                class="float-end">{{selectedTierName}}</span><br>
                                            <strong>Periodicity:</strong> <span
                                                class="float-end">{{selectedTierTime}}</span><br>
                                            <hr>
                                            <strong>TOTAL:</strong> <span class="float-end">${{selectedTierCost}}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mt-3">
                                <p>Card Data</p>
                                <div ref="cardElement"></div>
                            </div>
                            <div class="col-md-12 mt-5 text-center">
                                <vs-button @click="processPayment()" block>Confirm Payment</vs-button>
                                <a class="text-link smallText text-center" @click="backToTier()">Return</a>
                            </div>


                        </div>
                        <div class="row" v-show="tierOn">
                            <div class="col-md-12">
                                <h4>Configuration</h4>
                                <p>Let's configure your first tier.</p>
                                <p>
                                    <strong>Tier:</strong> {{loadTier.package_name}} <br>
                                    <strong>Features: </strong> <br>
                                    <span v-for="(item, index) in loadTier.features" :key="index">
                                        -{{item.feature_name}} <br>
                                    </span>
                                </p>
                                <div class="card main-card">
                                    <div class="card-body">
                                        <div class="form-group">
                                            <span class="text-muted mt-3">1.- Select your tier:</span>
                                            <select class="form-control" v-model="tierSelected">
                                                <option value="0" selected>--Select your tier--</option>
                                                <option :value="loadTier.package_id">{{loadTier.package_name}}</option>
                                            </select>
                                        </div>
                                        <div class="form-group mt-2">
                                            <span class="text-muted mt-3">2.- Create a domain name. </span>
                                            <input type="text" class="form-control" v-model="farmName"
                                                placeholder="E.g. Agtlink main farm">
                                            <p>Domain name is the main area where the tier will exist, domain name can
                                                be the name of your company or some subdivision of it.</p>
                                        </div>

                                        <div class="form-group mt-2">
                                            <span class="text-muted mt-3">Storage Path</span>
                                            <input type="text" class="form-control mr-1" placeholder="Select a Path"
                                                v-model.trim="storage_path" maxlength="500" disabled />
                                            <!-- <a href="#" class="text-link smallText me-2"
                                                v-on:click="handleFileUpload()">Select Path</a> -->
                                            <p class="text-muted">Storage path must be selected in the desktop app.</p>

                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mt-5 text-center">
                                <vs-button @click="continue1()" block>Continue</vs-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-md-12 text-center">
                    <span class="nav-link smallText d-flex align-items-center justify-content-center">Powered by
                    </span>
                    <img class="align-items-center justify-content-center" src="@/assets/stripe.svg" alt="Stripe Logo"
                        width="99">
                </div>
            </div>
        </div>

    </div>
</template>
<script>
    import { loadStripe } from '@stripe/stripe-js';
    import Navbar from '@/components/Navbar.vue';
    import Sidebar from '@/components/Footer.vue';
    import axios from 'axios';
    var config = require("@/config/api.json");
    const API = config.api;
    import { CardElement } from '@stripe/stripe-js';
    import {
        createUserWithEmailAndPassword,
        sendEmailVerification,
        EmailAuthProvider,
        updateEmail,
        reauthenticateWithCredential,
        updatePassword,
        updateProfile
    } from "firebase/auth";
    import { auth } from "@/fb";

    export default {
        name: 'Dashaboard',
        components: {
            Navbar, Sidebar
        },
        props: {
            itemData: Object, // Declara una propiedad llamada itemData
        },
        watch: {
            billed(value) {
                if (value) {
                    this.billTime = "annnually"
                    this.tier1 = 600
                    this.tier2 = 1200
                    this.tier2p = 1600
                } else {
                    this.billTime = "monthly"
                    this.tier1 = 50
                    this.tier2 = 100
                    this.tier2p = 160
                }
            },
        },
        data() {
            return {
                active: false,
                billed: true,
                billTime: "monthly",
                tier1: 50,
                tier2: 100,
                tier2p: 160,
                tiers: [],
                tiersOn: true,
                selectedOn: false,
                tierOn: false,
                selectedTierId: null,
                selectedTierCost: null,
                selectedTierName: null,
                selectedTierTime: null,
                cardElement: null,
                stripe: null,
                storedUser: null,
                loadTier: [],
                tierSelected: 0,
                farmName: null,
                storage_path: "C:\\Users\%USERPROFILE%\\Desktop",
                packageId: null
            };
        },

        async mounted() {

            await this.getTiers();
            setTimeout(() => {
                console.log("Selected data RENEW", this.itemData)
            }, 1000);
            setTimeout(() => {
                this.initializeStripe();
            }, 2000);

            this.storedUser = JSON.parse(localStorage.getItem('user'));


            // ipcRenderer.on("getRoute", (event, args) => {
            //     if (!args.route[0]) {
            //         return;
            //     } else {
            //         var regex = /\\/g;
            //         var FilePath = args.route[0].replace(regex, "\\\\");
            //         this.storage_path = FilePath;
            //     }
            // });

        },
        methods: {
            async reloadData() {
                localStorage.removeItem('user');
                await this.checkAuthState();
                const user = auth.currentUser;
                if (user) {
                    this.storedUser = JSON.parse(localStorage.getItem('user'));
                    console.log(this.storedUser)
                    if (parseInt(this.storedUser.tiers.length) >= 1) {
                        for (const element of this.storedUser.tiers) {
                            console.log('element', element);
                            if (parseInt(element.status) === 1) {
                                let tierId = element.id;
                                this.packageId = element.id;
                                this.active = true;
                                this.tierOn = true;
                                this.tiersOn = false;
                                this.selectedOn = false;
                                this.getTierById(element.tier, tierId);
                                break; // Salir del bucle tan pronto como se cumpla la condición
                            }
                        }
                    }

                }
            },
            async continue1() {
                if (parseInt(this.tierSelected) == 0) {
                    this.notify('error', 'Please select yout tier');
                    return;
                }
                if (!this.vEmpty(this.farmName, 'Farm Name')) {
                    return;
                }

                if (this.farmName.length > 50) {
                    this.notify('error', 'Too many characters for a farm name');
                    return;
                }

                if (!this.vEmpty(this.storage_path, 'Storage Path')) {
                    return;
                }

                if (this.storage_path.length > 255) {
                    this.notify('error', 'Storage path is too large');
                    return;
                }

                console.log(this.user)

                const newFarm = {
                    user_id: this.storedUser.id,
                    name: this.farmName,
                    storage_path: this.storage_path,
                    triedId: this.tierSelected,
                    packageId: this.packageId,
                    features: JSON.stringify(this.loadTier.features)
                };

                this.openLoading();

                try {
                    await axios.post(`${API}/register-farm-tier`, newFarm);
                } catch (error) {
                    this.closeLoading();
                    this.notify('error', error.response.data.error);
                } finally {
                    this.notify('success', 'Tier was registered successfully');
                    this.closeLoading();
                    setTimeout(() => {
                        location.reload();
                    }, 1000);
                }
            },
            handleFileUpload() {
                // ipcRenderer.send("select-dirs", true);
            },
            async initializeStripe() {
                this.stripe = await loadStripe('pk_test_4YPnh1YgvCH9ltCRyOYK8Irm');
                const elements = this.stripe.elements();
                this.cardElement = elements.create('card', {
                    style: {
                        base: {
                            fontSize: '16px',
                            color: "#0e7e29"
                        },
                    }
                });
                this.cardElement.mount(this.$refs.cardElement);
            },
            backToTier() {
                this.selectedOn = false;
                this.tierOn = false;
                this.tiersOn = true;
                this.selectedTierId = null;
                this.selectedTierCost = null;
                this.selectedTierName = null;
                this.selectedTierTime = null;
            },
            selectTier(id, cost, name, time) {
                this.selectedTierId = id;
                this.selectedTierCost = cost;
                this.selectedTierName = name;
                this.selectedTierTime = time;
                this.tiersOn = false;
                this.tierOn = false;
                this.selectedOn = true;

            },
            async getTiers(item) {
                this.tiers = []
                await axios.get(`${API}/getTiers`)
                    .then((data) => {
                        let response = data.data
                        if (response.length > 0) {
                            this.tiers = response
                        }
                    })
                    .catch(function (error) {
                        console.log('entra')
                        console.log(error);
                    });
            },
            async getTierById(id, packageId) {
                this.loadTier = []
                await axios.get(`${API}/getTierbyId/${id}`)
                    .then((data) => {
                        let response = data.data
                        this.loadTier = response
                        this.loadTier.package_id = packageId
                        console.log('load TIER')
                        console.log(this.loadTier)

                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            async processPayment() {
                this.openLoading();
                const { token, error } = await this.stripe.createToken(this.cardElement);
                if (error) {
                    // Manejar el error (puede mostrar un mensaje de error al usuario)
                    console.error(error.message);
                    this.closeLoading();
                } else {
                    // Enviar el token al backend
                    this.sendTokenToBackend(token);
                }
            },
            async sendTokenToBackend(token) {
                console.log('paymentssss', this.itemData)
                try {
                    const response = await axios.post(`${API}/make-payment-renew`, {
                        token: token.id,
                        amount: parseInt(this.selectedTierCost * 100),
                        user: this.storedUser,
                        tier: `Renew payment for ${this.selectedTierTime} ${this.selectedTierName}`,
                        tierId: this.selectedTierId,
                        tierMode: this.selectedTierTime,
                        tierRealId: this.itemData.triedId,
                        endDate: this.itemData.endDate,

                    });

                    // Manejar la respuesta del backend (puede mostrar un mensaje de éxito al usuario)
                    console.log(response.data);
                    this.cardElement.clear();
                    this.closeLoading();
                    this.notify('success', 'Successfull Payment');
                    this.cardElement.clear();
                    setTimeout(() => {
                        // location.reload()
                        this.$emit("renew-done", true);
                    }, 1000);
                } catch (error) {
                    this.closeLoading();
                    this.cardElement.clear();
                    // Manejar errores del backend (puede mostrar un mensaje de error al usuario)
                    console.error(error.response.data);
                }
            },
        },
    }
</script>