<template>
    <div>
        <div class="container ">
            <div class="row d-flex justify-content-center">
                <div class="col-md-6 mt-5 mb-5 text-center" v-if="verifyEmail">
                    <span class="material-icons md-48 text-primary mb-3">beenhere</span>
                    <h1 class="mb-3">Thank you!</h1>
                    <p>Your email has been verified</p>
                    <routerLink to="/" class="btn btn-dark mt-3">Continue</routerLink>
                </div>
                <div class="col-md-6 mt-5 mb-5 text-center" v-if="resetPassword">
                    <span class="material-icons md-48 text-primary mb-3">password</span>
                    <h1 class="mb-3">{{passEmail}}</h1>
                    <p>Enter the new password</p>
                    <input class="form-control" type="password" placeholder="New password" v-model.trim="newPass">
                    <input class="form-control mt-3" type="password" placeholder="Confirm new password"
                        v-model.trim="confirmPass">
                    <button class="btn btn-dark mt-3" @click='changePass()'>Continue
                    </button>
                </div>
                <div class="col-md-6 mt-5 mb-5 text-center" v-if="passSuccess">
                    <span class="material-icons md-48 text-primary mb-3">beenhere</span>
                    <h1 class="mb-3">Thank you!</h1>
                    <p>Your password has been successfully updated</p>
                    <routerLink to="/" class="btn btn-dark mt-3">Continue</routerLink>
                </div>
                <div class="col-md-6 mt-5 mb-5 text-center" v-if="errorMessage">
                    <span class="material-icons md-48 text-warning mb-3">error</span>
                    <h1 class="mb-3">Warning</h1>
                    <p>{{errDisplay}}</p>
                    <routerLink to="/" class="btn btn-dark mt-3">Continue</routerLink>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import { getAuth, applyActionCode,  verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";
  import { firebaseConfig } from "@/fb";
  import axios from 'axios';
  var config = require("@/config/api.json");
  const API = config.api;


    // const app = initializeApp(firebaseConfig);
    const auth = getAuth();


    export default {
        name: 'Job',
        data() {
            return {
                uId: null,
                displayName: null,
                photoURL: null,
                loading: false,
                uEmail: null,
                uPass: null,
                showLogin: true,
                showRecovery: false,
                recoveryMail: null,
                showRoute: null,
                resetPassword: false,
                verifyEmail: false,
                errorMessage: false,
                errDisplay: null,
                newPass: null,
                confirmPass: null,
                authPass:null,
                actionCodePass:null,
                passSuccess:false
            }
        },
        beforeMount() {
            var vm = this;
            vm.resetPassword = false
            vm.verifyEmail = false
            vm.errorMessage = false
            if (this.$route.query) {
                const mode = this.$route.query.mode
                const actionCode = this.$route.query.oobCode;
                const apiKey = this.$route.query.apiKey;

                if (!mode) {
                    vm.notify('error', 'Data is corrupted, please try to get an other email')
                    return
                }
                if (!actionCode) {
                    vm.notify('error', 'Data is corrupted, please try to get an other email')
                    return
                }
                if (!apiKey) {
                    vm.notify('error', 'Data is corrupted, please try to get an other email')
                    return
                }

                switch (mode) {
                    case 'resetPassword':
                        this.handleResetPassword(auth, actionCode);
                        break;
                    case 'verifyEmail':
                        this.handleVerifyEmail(auth, actionCode);
                        break;
                    default:
                        vm.notify('error', 'Data is corrupted, please try to get an other email')
                        return;
                }
            }
        },
        mounted() {

            window.scrollTo({ top: 0, behavior: 'smooth' });

        },
        methods: {

            changePass() {
                var vm = this
                if(!vm.newPass){
                    vm.notify('error', 'Please enter your new password')
                    return;
                }
                if(vm.newPass.length<6){
                    vm.notify('error', 'Password should be at least 6 characters')
                    return;
                }
                if(vm.confirmPass != vm.newPass){
                    vm.notify('error', 'The passwords do not match')
                    return;
                }
                confirmPasswordReset(vm.authPass, vm.actionCodePass, vm.newPass).then((resp) => {
                    vm.resetPassword=false
                    vm.passSuccess=true
                }).catch((error) => {
                    if(error.code='auth/weak-password'){
                        vm.notify('error', 'Password should be at least 6 characters')
                        return;
                    }else{
                        vm.errorMessage = true
                        vm.errDisplay = 'This verification link has expired or was previously used, please go to dashboard'
                    }
                });
            },


            handleResetPassword(auth, actionCode) {
                var vm = this
                verifyPasswordResetCode(auth, actionCode).then((email) => {
                    vm.passEmail = email;
                    vm.resetPassword = true;
                    vm.authPass = auth
                    vm.actionCodePass = actionCode
                }).catch((error) => {
                    vm.errorMessage = true
                    if (error.code == "auth/invalid-action-code") {
                        vm.errDisplay = 'This verification link has expired or was previously used, please go to dashboard'
                    } else {
                        vm.errDisplay = 'Data is corrupted, please try to get an other email'
                    }
                });
            },

            handleVerifyEmail(auth, actionCode) {
                var vm = this
                applyActionCode(auth, actionCode).then((resp) => {
                    this.verifyEmail = true
                }).catch((error) => {
                    vm.errorMessage = true
                    if (error.code == "auth/invalid-action-code") {
                        vm.errDisplay = 'This verification link has expired or was previously used, please go to dashboard'
                    } else {
                        vm.errDisplay = 'Data is corrupted, please try to get an other email'
                    }
                });
            },

            sendRecovery() {
                var vm = this
                if (!vm.recoveryMail) {
                    vm.notify('error', 'Email is empty')
                    return
                }
                if (!validator.isEmail(vm.recoveryMail)) {
                    vm.notify('error', 'Please enter a valid email')
                    return
                }
                sendPasswordResetEmail(auth, vm.recoveryMail)
                    .then(() => {
                        vm.notify('Great', '☑️ You will receive an email shortly to recover your password')
                        vm.recoveryMail = null
                        return
                    })
                    .catch((error) => {
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        vm.notify('error', `${errorCode}`)
                        vm.recoveryMail = null
                        return
                    });
            },

            openLoading() {
                this.loading = this.$vs.loading({
                    target: this.$refs.content,
                    color: 'dark'
                })
            },

            closeLoading() {
                setTimeout(() => {
                    this.loading.close()
                }, 1000);
            },

            notify(title, message) {
                const noti = this.$vs.notification({
                    position: 'bottom-center',
                    color: 'dark',
                    title: title,
                    text: message
                })
            },

        },
    }
</script>