<template>
  <div class="container mt-5 mb-5">
    <div class="row justify-content-center align-items-center">
      <div class="col-md-6 col-sm-12 col-lg-4">
        <div class="card main-card">
          <div class="card-body">
            <form @submit.prevent="registerUser">
              <div class="text-center">
                <img src="@/assets/logo.png" width="133">
              </div>
              <h5 class="text-center">User Registration</h5>
              <div class="mb-3">
                <label for="companyName" class="form-label text-start">Company Name</label>
                <input type="text" class="form-control" placeholder="Company" v-model="companyName" maxlength="50"
                  required>
              </div>
              <div class="mb-3">
                <label for="displayName" class="form-label text-start">Your Name</label>
                <input type="text" class="form-control" placeholder="John Doe" v-model="displayName" maxlength="50"
                  required>
              </div>
              <div class="mb-3">
                <label for="email" class="form-label text-start">Email</label>
                <input type="email" class="form-control" placeholder="user@agtl.ink" v-model="email" required>
              </div>
              <div class="mb-3">
                <label for="password" class="form-label text-start">Password</label>
                <input type="password" class="form-control" v-model="password" required>
              </div>
              <div class="mb-3">
                <label for="confirmPassword" class="form-label text-start">Confirm Password</label>
                <input type="password" class="form-control" v-model="confirmPassword" required>
              </div>
              <vs-button type="submit" class="mt-3" block>
                Register
              </vs-button>
              <!-- <button type="submit" class="btn btn-primary w-100"><span class="spanButton">Register</span></button> -->
              <hr>
              <p class="text-center smallText mt-3">
                Already have an account? <router-link to="/">Sign in</router-link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import { createUserWithEmailAndPassword, updateProfile, sendEmailVerification } from "firebase/auth";
  import { auth } from "@/fb";
  import axios from 'axios';
  var config = require("@/config/api.json");
  const API = config.api;

  export default {
    name: 'Register',
    data() {
      return {
        displayName: '',
        email: '',
        password: '',
        confirmPassword: '',
        companyName: ''
      };
    },
    methods: {
      ...mapActions(['updateDisplayName']),
      async registerUser() {
        // Validar los campos requeridos
        if (!this.vEmpty(this.companyName, "Company Name")) {
          return;
        }

        if (!this.vEmpty(this.displayName, 'Display Name')) {
          return;
        }

        if (!this.vEmail(this.email)) {
          return;
        }

        if (!this.vEmpty(this.password, 'Password')) {
          return;
        }

        if (!this.vEmpty(this.confirmPassword, 'Confirm Password')) {
          return;
        }

        if (this.password !== this.confirmPassword) {
          this.notify('error', 'Passwords do not match');
          return;
        }

        this.openLoading();

        await createUserWithEmailAndPassword(auth, this.email, this.password)
          .then((userCredential) => {
            const user = userCredential.user;
            updateProfile(user, { displayName: this.displayName })
              .then(() => {
                sendEmailVerification(user)
                  .then(() => {
                    this.updateDisplayName(this.displayName);
                    //llamada fetch que envia username, uid y displayName a https://agtl.ink/registerUser
                    const userData = {
                      uid: user.uid,
                      company: this.companyName,
                    };
                    axios.post(`${API}/create-user`, userData)
                      .then(response => {
                        console.log('CREACION', response)
                        // Lógica adicional después de asignar los permisos al usuario
                        this.checkAuthState();
                      })
                      .catch(error => {
                        console.error('Error al asignar permisos al usuario:', error);
                        this.notify('error', 'Error al asignar permisos al usuario');
                      });
                    this.notify('success', 'Registration successful. Please check your email for verification.');


                  })
                  .catch((error) => {
                    console.log(error)
                    this.notify('error', `Error sending verification email: ${error.code}`);
                    console.log(`Error sending verification email: ${error.code}`);
                  })
                  .finally(() => {
                    this.closeLoading();
                  });
              })
              .catch((error) => {
                this.notify('error', `Error updating display name: ${error.code}`);
                console.log(`Error updating display name: ${error.code}`);
              });
          })
          .catch((error) => {
            this.notify('error', `Error registering user: ${error.code}`);
            console.log(`Error registering user: ${error.code}`);
          })
          .finally(() => {
           setTimeout(() => {
            this.displayName = null;
            this.email = null;
            this.password = null;
            this.confirmPassword = null;
            this.companyName = null;  
           }, 1500);
            this.closeLoading();
          });
      }
    },
  }
</script>