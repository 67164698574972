<template>
  <div class="container-fluid mt-2 mb-5">

    <vs-dialog  full-screen not-center v-model="nodeInfoDialog">
      <template #header>
        <h4 class="not-margin">My Node <b>details</b></h4>
      </template>
      <div class="con-content">
        <p>
          <strong>Total Nodes:</strong> {{ myNodes.length }}<br>
          <strong>Total Sensors:</strong> {{ myNodes.reduce((total, node) => total + JSON.parse(node.sensors).length, 0)
          }}<br>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>Node ID</th>
                <th>Node Name</th>
                <th>Registered Date</th>
                <th>Device ID</th>
                <th>Firmware Version</th>
                <th>Active</th>
                <th>Latitude</th>
                <th>Longitude</th>
                <th>Elevation</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(node, index) in myNodes" :key="index">
                <td>{{ node.node_id }}</td>
                <td>{{ node.node_name }}</td>
                <td>{{ moment.unix(node.registered_date).format('ll') }}</td>
                <td>{{ node.device_id }}</td>
                <td>{{ node.firmware_version }}</td>
                <td>{{ node.active }}</td>
                <td>{{ node.latitude }}</td>
                <td>{{ node.longitude }}</td>
                <td>{{ node.elevation }}</td>
                <td>
                  <button class="btn btn-sm btn-dark" type="button" data-bs-toggle="collapse"
                    :data-bs-target="'#collapseSensors' + index" aria-expanded="false"
                    :aria-controls="'collapseSensors' + index">
                    See Sensors
                  </button>
                  <div :id="'collapseSensors' + index" class="collapse mt-2">
                    <ul>
                      <li v-for="(sensor, sensorIndex) in JSON.parse(node.sensors)" :key="sensorIndex">
                        <strong>Sensor ID:</strong> {{ sensor.lsid }}<br>
                        <strong>Type:</strong> {{ sensor.sensor_type }}<br>
                        <strong>Category:</strong> {{ sensor.category }}<br>
                        <strong>Manufacturer:</strong> {{ sensor.manufacturer }}<br>
                        <strong>Product Name:</strong> {{ sensor.product_name }}<br>
                        <strong>Port Number:</strong> {{ sensor.port_number }}<br>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        </p>
      </div>
    </vs-dialog>

    <vs-dialog not-center v-model="delSensor">
      <template #header>
        <h4 class="not-margin">Delete <b>sensor</b></h4>
      </template>
      <div class="con-content">
        <p>
          Are you sure you want to delete the sensor?
        </p>
      </div>
      <template #footer>
        <div class="container">
          <div class="row">
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="deleteSensor()" transparent> Yes! </vs-button>
            </div>
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="delSensor = false" danger transparent>
                Cancel
              </vs-button>
            </div>
          </div>
        </div>
      </template>
    </vs-dialog>


    <vs-dialog not-center v-model="nodeDetails">
      <template #header>
        <h4 class="not-margin">Available nodes: <b>{{itemData.davis_nodes}}</b></h4>
      </template>
      <div class="con-content">
        <div class="row">
          <div class="col-md-12">
            <p>
              <strong>Total Nodes:</strong> {{ myNodes.length }}<br>
              <strong>Total Sensors:</strong> {{ myNodes.reduce((total, node) => total +
              JSON.parse(node.sensors).length, 0)
              }} <br>
              Select a node to see the details
              <select class="form-control form-control-sm" v-model="selectedNode" @change="getNodeSensors">
                <option value="0">--Select a value--</option>
                <option v-for="(node, index) in davisNodes" :key="index" :value="node">
                  {{ node.node_id }}-{{ node.node_name }}</option>
              </select>
            </p>
            <p class="smallText">
              <template v-if="selectedNode!=0">
                <ul class="list-group smallText">
                  <li class="list-group-item"><strong>node_id:</strong> {{ selectedNode.node_id }}</li>
                  <li class="list-group-item"><strong>node_name:</strong> {{ selectedNode.node_name }}</li>
                  <li class="list-group-item"><strong>registered_date:</strong> {{
                    moment.unix(selectedNode.registered_date).format('ll') }}</li>
                  <li class="list-group-item"><strong>device_id:</strong> {{ selectedNode.device_id }}</li>
                  <li class="list-group-item"><strong>device_id_hex:</strong> {{ selectedNode.device_id_hex }}</li>
                  <li class="list-group-item"><strong>firmware_version:</strong> {{ selectedNode.firmware_version }}
                  </li>
                  <li class="list-group-item"><strong>active:</strong> {{ selectedNode.active }}</li>
                  <li class="list-group-item"><strong>station_id:</strong> {{ selectedNode.station_id }}</li>
                  <li class="list-group-item"><strong>station_id_uuid:</strong> {{ selectedNode.station_id_uuid }}</li>
                  <li class="list-group-item"><strong>station_name:</strong> {{ selectedNode.station_name }}</li>
                  <li class="list-group-item"><strong>latitude:</strong> {{ selectedNode.latitude }}</li>
                  <li class="list-group-item"><strong>longitude:</strong> {{ selectedNode.longitude }}</li>
                  <li class="list-group-item"><strong>elevation:</strong> {{ selectedNode.elevation }}</li>
                </ul> <br>
                <strong>Related sensor(s) inside the node</strong>
                <!-- <select class="form-control form-control-sm" v-model="selectedNodeSensor">
                <option value="0">--Select a value--</option>
                <option v-for="(sensor, index) in nodeSensors" :key="index" :value="sensor">
                  {{ sensor.lsid }}: {{ sensor.manufacturer }} {{ sensor.product_name }}, type: {{sensor.sensor_type}} </option>
              </select> -->
                <ul class="list-group smallText">
                  <li class="list-group-item" v-for="(sensor, index) in nodeSensors" :key="index" :value="sensor">
                    <strong>{{ sensor.lsid }}:</strong> {{ sensor.manufacturer }} {{ sensor.product_name }}, type:
                    {{sensor.sensor_type}}
                  </li>
                </ul>
              </template>
            </p>
          </div>
        </div>

      </div>
      <template #footer>
        <div class="container">
          <div class="row">
            <div class="col-6 d-flex align-items-center justify-content-center">
              <!-- <vs-button @click="linkNode(selectedNode, nodeSensors)" transparent> Register Node</vs-button> -->
              <template v-if="myNodes.length < 20">
                <vs-button @click="linkNode(selectedNode, nodeSensors)" transparent> Register Node</vs-button>
              </template>
              <template v-else>
                <p class="text-danger">You have reached the maximum number of nodes allowed per station.</p>
              </template>

            </div>
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="nodeDetails = false" danger transparent>
                Cancel
              </vs-button>
            </div>
          </div>
        </div>
      </template>
    </vs-dialog>

    <div class="row justify-content-center align-items-center">
      <div class="col-md-12 d-flex align-items-center">
        <img src="@/assets/davis.png" width="33" class="me-2" />
        <h3 class="mb-0">Davis Data</h3>
      </div>
      <div class="col-md-12 mt-2 mb-5">
        <div class="row">
          <div class="col-md-4 " v-for="(item, index) in farms" :key="index">
            <div class="card main-card h-100">
              <div class="card-body">
                <h5 class="text-main-gradient">{{ item.name }}</h5>
                <p>
                  <strong>Nodes:</strong> {{ totalUniversalStations.reduce((total, station) => total +
                  station.totaNodes, 0) }}
                  <strong>Sensors:</strong> {{ totalUniversalStations.reduce((total, station) => total +
                  station.totalSensorsInside, 0) }}
                </p>
                <!-- <span class="text-muted smallText"><span
                  class="material-icons smallText me-1">folder</span>{{item.storage_path}}</span> <br> -->
                <div class="card card-station "
                  v-for="(itemStation, indexStation) in item.stations.filter(station => station.sensor_type === 1)"
                  :key="indexStation">

                  <div class="card-body">
                    <!-- <span class="text-main-gradient-secondary">{{itemStation.name}}</span> <br> -->
                    <!-- <span class="material-icons smallText me-1">category</span>
                    <span class="text-muted smallText">{{item.stations[0].sensor_description}} </span> -->
                    <!-- <p>My stations</p> -->
                    <ul class="list-group smallText">
                      <li class="list-group-item" v-for="(item2, index2) in itemStation.sensors" :key="index2"
                        v-if="parseInt(item2.sensor_type) == 1">
                        <template v-if="parseInt(item2.last_status) == 0">
                          <span class="smallText d-flex align-items-center justify-content-left">
                            <div class="circle-gray me-1"></div>
                            {{ item2.name }}, agtlink-id: {{item2.id}}
                            <br>
                          </span>
                          <strong>Station Id:</strong> {{ JSON.parse(JSON.parse(item2.config).config).sensorId }}<br>

                          <!-- <a class="text-link" @click="
                              turnDavis(
                                item2.id,
                                1,
                                item2.name,
                                JSON.parse(JSON.parse(item2.config).config).sensorId,
                                JSON.parse(JSON.parse(item2.config).config),
                                item.storage_path
                              )
                            ">Turn on</a> -->
                          <!-- <a class="text-link" @click="
                              turnDavis(
                                item2.id,
                                1,
                                item2.name,
                                item2.config,
                                itemStation.config,
                                item.storage_path
                              )
                            ">Turn on</a> -->
                          <a class="text-link ms-2" @click="last_mesure(item2)">Last measure</a>
                          <!-- <a class="text-link ms-2" @click="delete_davis(item2,JSON.parse(JSON.parse(item2.config).config).sensorId)"
                            v-if="hasPermission('sensor',user.permissions)">Delete</a> -->
                        </template>
                        <template v-else>
                          <span class="smallText d-flex align-items-center justify-content-left">
                            <div class="circle me-1"></div>
                            {{ item2.name }}, agtlink-id: {{item2.id}}
                          </span>
                          <strong>Station Id:</strong> {{ JSON.parse(JSON.parse(item2.config).config).sensorId }}<br>
                          <!-- <a class="text-link" @click="
                              turnDavis(item2.id, 0, item2.name, JSON.parse(JSON.parse(item2.config).config).sensorId,  JSON.parse(JSON.parse(item2.config).config), item.storage_path)
                            ">Turn off</a> -->
                          <a class="text-link ms-2" @click="last_mesure(item2)">Last measure</a>
                          <!-- <a class="text-link ms-2" @click="delete_davis(item2,JSON.parse(JSON.parse(item2.config).config).sensorId)"
                            v-if="hasPermission('sensor',user.permissions)">Delete</a> -->
                        </template>
                        <br>
                        <a class="text-link"
                          @click="
                        nodeDetail(item2.id,1,item2.name,JSON.parse(JSON.parse(item2.config).config).sensorId,JSON.parse(JSON.parse(item2.config).config),item.storage_path)">➕
                          Add node</a> <br>
                        <a class="text-link"
                          @click="
                        enableNodeDialog(item2.id,1,item2.name,JSON.parse(JSON.parse(item2.config).config).sensorId,JSON.parse(JSON.parse(item2.config).config),item.storage_path)">
                          📝 My nodes</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <transition name="fade">
            <div class="col-md-8" v-if="lastMeasure && lastMeasure.length > 0">
              <h3 class="text-main-gradient-secondary">Last measure</h3>
              <p>
                <strong>Station:</strong> {{ selectedSensor.name }}<br />
                <strong>Station_id:</strong> {{JSON.parse(JSON.parse(selectedSensor.config).config).sensorId}}
                <br />
              </p>
              <div class="row">
                <div class="col-md-6">
                  <ul class="list-group smallText">
                    <li class="list-group-item"><strong class="text-main-gradient-secondary">1.-Observation_time:
                      </strong>{{ moment(lastMeasure[0].observation_time).format("MMM Do YYYY HH:m:ss") }}
                    </li>
                    <li class="list-group-item"><strong class="text-main-gradient-secondary">2.-Barometer:
                      </strong>{{lastMeasure[0].bar}}</li>
                    <li class="list-group-item"><strong class="text-main-gradient-secondary">3.- Relative_humidity:
                      </strong>{{lastMeasure[0].hum_out}}</li>
                    <li class="list-group-item"><strong class="text-main-gradient-secondary">4.- Temp_f:
                      </strong>{{lastMeasure[0].temp_out}}</li>
                    <li class="list-group-item"><strong class="text-main-gradient-secondary">5.- Wind_dir:
                      </strong>{{lastMeasure[0].wind_dir}}</li>
                    <li class="list-group-item"><strong class="text-main-gradient-secondary">6.- Wind_mph:
                      </strong>{{lastMeasure[0].wind_speed}}</li>
                    <li class="list-group-item"><strong class="text-main-gradient-secondary">7.- Et_day:
                      </strong>{{lastMeasure[0].et_day}}</li>
                    <li class="list-group-item"><strong class="text-main-gradient-secondary">8.- Rain_day_in:
                      </strong>{{lastMeasure[0].rain_day_in}}</li>
                    <li class="list-group-item"><strong class="text-main-gradient-secondary">9.- Solar_radiation:
                      </strong>{{lastMeasure[0].solar_rad}}</li>
                    <li class="list-group-item"><strong class="text-main-gradient-secondary">10.- Ultraviolet_rays:
                      </strong>{{lastMeasure[0].uv}}</li>
                    <li class="list-group-item"><strong class="text-main-gradient-secondary">11.- Temperature_probe:
                      </strong>{{lastMeasure[0].temperature_probe}}</li>


                    <li class="list-group-item"><strong class="text-main-gradient-secondary">12.- Soil_Moisture_SM150:
                      </strong>{{lastMeasure[0].soil_moisture_sm150}}</li>
                    <li class="list-group-item"><strong class="text-main-gradient-secondary">13.- Normally Closed:
                      </strong>{{lastMeasure[0].normally_closed}}</li>
                    <li class="list-group-item"><strong class="text-main-gradient-secondary">14.- Et_yesterday:
                      </strong>{{lastMeasure[0].etPast}}</li>
                    <li class="list-group-item"><strong class="text-main-gradient-secondary">15.- Rain_weekly_in:
                      </strong>{{lastMeasure[0].weekly_rain}}</li>
                    <li class="list-group-item"><strong class="text-main-gradient-secondary">16.- Rain_monthly_in:
                      </strong>{{lastMeasure[0].monthly_rain}}</li>
                    <li class="list-group-item"><strong class="text-main-gradient-secondary">17.- Rain_yearly_in:
                      </strong>{{lastMeasure[0].yearly_rain}}</li>
                  </ul>
                </div>
                <div class="col-md-6">
                  <ul class="list-group smallText">
                    <li v-for="(value, index) in lastMeasure[0].m150x" :key="index" class="list-group-item">
                    <strong class="text-main-gradient-secondary">{{ 18 + index }}.- Soil Moisture {{value.name}}:</strong> {{ value.value }}
                    </li>
                  </ul>
                </div>
                <div class="col-md-6"></div>
              </div>

            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapActions } from "vuex";
  import axios from "axios";
  import SetInterval from "set-interval";
  import moment from 'moment'; // Importa Moment.js

  var config = require("@/config/api.json");
  const API = config.api;
  export default {
    name: "SoilData",
    props: {
      itemData: Object, // Declara una propiedad llamada itemData
    },
    data() {
      return {
        user: null,
        farms: [],
        active: false,
        options: [],
        lastMeasure: [],
        selectedSensor: [],
        observations: [],
        delSensor: false,
        sampleTime: 1200000,
        davisNodes: [],
        nodeDetails: false,
        selectedNode: 0,
        selectedNodeSensor: 0,
        nodeSensors: [],
        selectedApiKey: null,
        selectedApiSecret: null,
        nodeInfoDialog: false,
        myNodes: [],
        universalSensors: [],
        totalSensors: 0,
        unviersalStations: [],
        totalUniversalStations: 0,

      };
    },

    async mounted() {
      const storedUser = localStorage.getItem("user");
      if (storedUser) {
        this.user = JSON.parse(storedUser);
        await this.getFarms();
        await this.getUniversalSensors();
        await this.getSensors();
        console.log('sensors', this.sensors)
      }
    },
    methods: {

      async getUniversalSensors() {
        this.totalUniversalStations = 0;
        try {
          const response = await axios.post(`${API}/totalDavisStationsData`, this.unviersalStations);
          if (response.data) {
            console.log('Universal Sensors Data:', response.data);
            this.totalUniversalStations = response.data;
            // Process the response data as needed
          } else {
            this.notify("error", "No data found for universal sensors");
          }
        } catch (error) {
          console.error("Error fetching universal sensors data:", error);
          this.notify("error", "Failed to fetch universal sensors data");
        }
      },


      async seeNodeDetail() {

        this.selectedNode = 0;
        this.selectedApiKey = station_config.apiKey;
        this.selectedApiSecret = station_config.apiSecret;
        console.log('TOGGLE SENSOR DETAILS--------------', id, value, name, stationId, station_config, path)
        console.log(stationId, station_config.apiKey, station_config.apiSecret)
        this.davisNodes = [];
        try {
          const response = await axios.post(`${API}/getNodes`, {
            id: id,
            stationId: stationId,
            apiKey: station_config.apiKey,
            secret: station_config.apiSecret
          });
          if (response.data && response.data.nodes) {
            this.nodeDetails = true;
            this.davisNodes = response.data.nodes;
          } else {
            this.davisNodes = [];
            this.nodeDetails = false;
            this.notify("error", "No nodes data found");
          }
          console.log('RESPONSE NODES', response.data.nodes)
        } catch (error) {
          console.error("Error", error);
          this.notify("error", "There are no nodes available for this station");
        }

      },

      async getNodeSensors() {
        console.log('NODE SENSOR DETAILS--------------', this.selectedNode)
        console.log('Keys>', this.selectedApiKey, this.selectedApiSecret)
        this.nodeSensors = [];
        this.selectedNodeSensor = 0;
        try {
          const response = await axios.post(`${API}/getNode-sensors`, {
            nodeId: this.selectedNode.device_id,
            apiKey: this.selectedApiKey,
            secret: this.selectedApiSecret
          });
          if (response.data && response.data.sensors) {
            this.nodeSensors = response.data.sensors;
          } else {
            this.notify("error", "No sensor data found");
          }
          console.log('RESPONSE SENSORS', response.data.sensors)
        } catch (error) {
          console.error("Error", error);
          this.notify("error", "Failed to get node sensors");
        }
      },
      async linkNode(node) {

        console.log('LINK NODE--------------', node)
        if (parseInt(node) == 0) {
          this.notify("error", "Please select a node");
          return;
        }
        if (this.itemData.davis_nodes == 0) {
          this.notify("error", "You have reached the maximum number of nodes allowed");
          return;
        }
        try {
          const response = await axios.post(`${API}/link-node`, {
            node_id: node.node_id,
            node_name: node.node_name,
            registered_date: node.registered_date,
            device_id: node.device_id,
            device_id_hex: node.device_id_hex,
            firmware_version: node.firmware_version,
            active: node.active,
            station_id: node.station_id,
            station_id_uuid: node.station_id_uuid,
            station_name: node.station_name,
            latitude: node.latitude,
            longitude: node.longitude,
            elevation: node.elevation,
            sensors: JSON.stringify(this.nodeSensors),
            farmId: this.itemData.id,
          });
          if (response.data.message === "ok") {
            this.notify("success", "Node linked successfully");
            // Esconder el dialogo y limpiar los datos
            this.nodeDetails = false;
            this.davisNodes = [];
            this.nodeSensors = [];
            setTimeout(() => {
              location.reload();
            }, 1000);

          } else {
            this.notify("error", "Failed to link node");
          }
        } catch (error) {
          console.error("Error", error);
          this.notify("error", "Failed to link node");
        }
      },
      async getSensors() {
        await axios
          .get(`${API}/api-sensors`)
          .then((response) => {
            this.sensors = response.data;
          });
      },


      storeRain(uid, stationId, rain) {
        let obj = { uid: uid, stationId: stationId, rain };
        localStorage.setItem(`r-${stationId}`, JSON.stringify(obj));
      },
      async getWeatherDataDavis(stationId, config, sensorId, name, path) {
        var vm = this;
        console.log('Weather Davis...............', stationId, sensorId)
        // let davisConnection = JSON.parse(config);
        let davisConnection = JSON.parse(config);
        this.observations = [];
        var weekly_rain = 0;
        var monthly_rain = 0;
        var yearly_rain = 0;
        var hasRemotes = false;

        await this.loadmyNodes(stationId);

        await axios
          .post(`${API}/getDavisData`, {
            apiKey: davisConnection.apiKey,
            apiSecret: davisConnection.apiSecret,
            stationId: stationId,
          })
          .then((response) => {
            console.log('RESPONSE DAVIS', response.data)
            vm.observations = response.data.sensors[0].data[0];

            let mainData = response.data.sensors;

            let totalData = response.data.sensors.length;
            if (totalData > 1) {
              mainData.forEach(function (element) {
                hasRemotes = true;

                if (vm.observations["etPast"] === undefined) {
                  vm.observations["etPast"] = 0;
                }
                if (element.etPast) {
                  vm.observations["etPast"] = element.etPast;
                }


                if (element.data_structure_type == 6 || element.data_structure_type == 2) {
                  console.log("---------------------SENSOR 2 and 6---------------------");
                  vm.observations["bar"] = element.data[0]["bar"];
                  vm.observations["hum_out"] = element.data[0]["hum_out"];
                  vm.observations["temp_out"] = element.data[0]["temp_out"];
                  vm.observations["wind_dir"] = element.data[0]["wind_dir"];
                  vm.observations["wind_speed"] = element.data[0]["wind_speed"];
                  vm.observations["et_day"] = element.data[0]["et_day"];
                  vm.observations["rain_day_in"] = element.data[0]["rain_day_in"];

                  vm.observations["weekly_rain"] = weekly_rain;
                  vm.observations["monthly_rain"] = monthly_rain;
                  vm.observations["yearly_rain"] = yearly_rain;

                  if (element.data[0]["rain_day_in"] !== undefined) {
                    vm.storeRain(vm.user, stationId, element.data[0]["rain_day_in"]);
                  }

                  vm.observations["solar_rad"] = element.data[0]["solar_rad"];
                  if (!element.data[0]["uv"]) {
                    vm.observations["uv"] = 0.0;
                  }
                  if (vm.observations["Normally_Closed"] > 0) {
                    vm.observations["Normally_Closed"] = 1;
                  } else {
                    vm.observations["Normally_Closed"] = 0;
                  }
                } else {
                  console.log('entra ELSE...')
                  vm.sensors.forEach((sensor) => {
                    if (sensor.id == element.sensor_type) {
                      var str = sensor.name;
                      var sensor_fixed = str.split(" ").join("_");
                      vm.observations[sensor_fixed] = element.data[0][sensor.field];
                      if (vm.observations["Soil_Moisture_SM150"]) {
                        vm.observations["Soil_Moisture_SM150"] = 0.0;
                      }
                      if (!vm.observations["Normally_Closed"] > 0) {
                        vm.observations["Normally_Closed"] = 1;
                      } else {
                        vm.observations["Normally_Closed"] = 0;
                      }

                      // Crear un objeto 'sensors' si no existe
                      if (!vm.observations.sensors) {
                        vm.observations.sensors = [];
                      }



                      vm.observations.sensors.push({
                        id: element.lsid,
                        name: sensor_fixed,
                        value: element.data[0][sensor.field]
                      });
                    }
                  });
                }
              });
            } else {
              vm.observations["weekly_rain"] = weekly_rain;
              vm.observations["monthly_rain"] = monthly_rain;
              vm.observations["yearly_rain"] = yearly_rain;
              // vm.storeRain(vm.user, api, vm.observations.rain_day_in);
              hasRemotes = false;
            }

            if (!vm.observations["bar"]) {
              vm.observations["bar"] = 0.0;
            }
            if (!vm.observations["hum_out"]) {
              vm.observations["hum_out"] = 0.0;
            }
            if (!vm.observations["temp_out"]) {
              vm.observations["temp_out"] = 0.0;
            }
            if (!vm.observations["wind_dir"]) {
              vm.observations["wind_dir"] = 0.0;
            }
            if (!vm.observations["wind_speed"]) {
              vm.observations["wind_speed"] = 0.0;
            }
            if (!vm.observations["et_day"]) {
              vm.observations["et_day"] = 0.0;
            }
            if (!vm.observations["rain_day_in"]) {
              vm.observations["rain_day_in"] = 0.0;
            }
            if (!vm.observations["solar_rad"]) {
              vm.observations["solar_rad"] = 0.0;
            }
            if (!vm.observations["uv"]) {
              vm.observations["uv"] = 0.0;
            }
            if (!vm.observations["temperature_probe"]) {
              vm.observations["temperature_probe"] = 0.0;
            }
            if (!vm.observations["etPast"]) {
              vm.observations["etPast"] = 0.0;
            }
            if (vm.observations["Soil_Moisture_SM150"]) {
              vm.observations["Soil_Moisture_SM150"] = 0.0;
            }
            vm.observations["sensorId"] = sensorId;
            vm.observations["sensorId"] = sensorId;
            vm.observations["user"] = vm.user;
            vm.observations["path"] = path;
            vm.observations["observation_time"] = moment
              .unix(response.data.generated_at)
              .format("lll");
          })
          .then(() => {
            let davis = vm.observations;
            var iccDb = [];
            console.log('LIMPIAR**********************************************************')
            iccDb["sensor_id"] = sensorId;
            iccDb["stationId"] = stationId;
            iccDb["observation_time"] = vm.observations["observation_time"];
            iccDb["bar"] = vm.observations["bar"];
            iccDb["hum_out"] = vm.observations["hum_out"];
            iccDb["temp_out"] = vm.observations["temp_out"];
            iccDb["wind_dir"] = vm.observations["wind_dir"];
            iccDb["wind_speed"] = vm.observations["wind_speed"];
            iccDb["et_day"] = vm.observations["et_day"];
            iccDb["rain_day_in"] = vm.observations["rain_day_in"];
            iccDb["solar_rad"] = vm.observations["solar_rad"];
            iccDb["uv"] = vm.observations["uv"];
            iccDb["temperature_probe"] = vm.observations["temperature_probe"];



            console.log('TOTAL SENSORS', vm.totalSensors)
            console.log('UNIVERSAL SENSORS', vm.universalSensors)

            let mSensors = [];
            mSensors = vm.observations.sensors;
            console.log("ALL SENSORS::::::::::::::::", mSensors);


            if (mSensors && mSensors.length > 0) {
              mSensors = mSensors.filter(sensor =>
                vm.universalSensors.some(universalSensor => universalSensor.lsid === sensor.id)
              );
            }

            console.log("Filtered Sensors:", mSensors);




            if (mSensors) {

              mSensors.forEach((sensor, index) => {
                iccDb[`m150${index + 1}`] = sensor.value !== null ? sensor.value : 0;
              });


              // if (!mSensors[0]) {
              //   iccDb["m1501"] = 0;
              // } else {
              //   iccDb["m1501"] = mSensors[0].value;
              // }
              // if (!mSensors[1]) {
              //   iccDb["m1502"] = 0;
              // } else {
              //   iccDb["m1502"] = mSensors[1].value;
              // }
              // if (!mSensors[2]) {
              //   iccDb["m1503"] = 0;
              // } else {
              //   iccDb["m1503"] = mSensors[2].value;
              // }
              // if (!mSensors[3]) {
              //   iccDb["m1504"] = 0;
              // } else {
              //   iccDb["m1504"] = mSensors[3].value;
              // }
              // if (!mSensors[4]) {
              //   iccDb["m1505"] = 0;
              // } else {
              //   iccDb["m1505"] = mSensors[4].value;
              // }
              // if (!mSensors[5]) {
              //   iccDb["m1506"] = 0;
              // } else {
              //   iccDb["m1506"] = mSensors[5].value;
              // }
              // if (!mSensors[6]) {
              //   iccDb["m1507"] = 0;
              // } else {
              //   iccDb["m1507"] = mSensors[6].value;
              // }
              // if (!mSensors[7]) {
              //   iccDb["m1508"] = 0;
              // } else {
              //   iccDb["m1508"] = mSensors[7].value;
              // }
              // if (!mSensors[8]) {
              //   iccDb["m1509"] = 0;
              // } else {
              //   iccDb["m1509"] = mSensors[8].value;
              // }
              // if (!mSensors[9]) {
              //   iccDb["m15010"] = 0;
              // } else {
              //   iccDb["m15010"] = mSensors[9].value;
              // }

            } else {
              // iccDb["m1501"] = '0';
              // iccDb["m1502"] = '0';
              // iccDb["m1503"] = '0';
              // iccDb["m1504"] = '0';
              // iccDb["m1505"] = '0';
              // iccDb["m1506"] = '0';
              // iccDb["m1507"] = '0';
              // iccDb["m1508"] = '0';
              // iccDb["m1509"] = '0';
              // iccDb["m15010"] = '0';
            }

            if (!vm.observations["Soil_Moisture_SM150"]) {
              iccDb["soil_moisture_sm150"] = 0;

            } else {
              iccDb["soil_moisture_sm150"] = vm.observations["soil_moisture_sm150"];
            }
            if (!vm.observations["Soil_Moisture_SM150T"]) {
              iccDb["soil_Moisture_SM150T"] = 0;

            } else {
              iccDb["soil_Moisture_SM150T"] = vm.observations["Soil_Moisture_SM150T"];
            }
            iccDb["normally_closed"] = vm.observations["Normally_Closed"] !== undefined ? vm.observations["Normally_Closed"] : 0;
            iccDb["etPast"] = vm.observations["etPast"];
            iccDb["weekly_rain"] = vm.observations["weekly_rain"];
            iccDb["monthly_rain"] = vm.observations["monthly_rain"];
            iccDb["yearly_rain"] = vm.observations["yearly_rain"];
            iccDb["path"] = path;
            iccDb["sensorId"] = sensorId;
            iccDb["sensorName"] = name;

            console.log("ICC", iccDb);

          })
          .catch(function (error) {
            console.log('catch!!')
            console.log(error)
            // vm.displayError(`Error ${error}`);
            return;
          });
      },

      async delete_davis(item, stationId) {
        console.log('DELETE DAVIS--------------', stationId);
        await this.loadmyNodes(stationId);
        this.selectedSensor = item;
        this.lastMeasure = [];
        this.delSensor = true;
      },

      deleteSensor() {

        const totalNodes = this.myNodes ? this.myNodes.length : 0;
        axios
          .post(`${API}/delete-davis-sensor`, {
            sensor_id: this.selectedSensor.id,
            station_id: this.selectedSensor.station_id,
            totalNodes:totalNodes
          })
          .then((response) => {
            this.notify("success", "Sensor deleted successfully");
          })
          .catch((error) => {
            console.error(error.response.data.error);
            this.notify("error", error.response.data.error);
          })
          .finally(() => {
            this.delSensor = false;
            this.$emit("davis-deleted", true);
          });
      },

      async last_mesure(item) {
        this.selectedSensor = item;
        this.lastMeasure = [];
        try {
          const response = await axios.get(
            `${API}/davis-last-measure/${this.selectedSensor.id}`
          );
          setTimeout(() => {
            this.lastMeasure = response.data;
          }, 333);
        } catch (error) {
          console.error("Error", error);
          this.notify("error", 'Failed to get last measure, there is no data');
        }
      },

      async loadmyNodes(stationId) {
        console.log('LOAD MY NODES--------------', stationId)
        this.myNodes = [];
        this.universalSensors = [];
        this.totalSensors = 0;
        await axios.get(`${API}/get-node-details`, {
          params: {
            station_id: stationId
          }
        })
          .then(response => {
            if (response.data && response.data.length > 0) {
              this.myNodes = response.data;
              this.myNodes.forEach(node => {
                console.log('NODES', node)
                const sensors = JSON.parse(node.sensors);
                this.totalSensors += sensors.length;
                this.universalSensors = this.universalSensors.concat(sensors);
              });
            } else {
              this.myNodes = [];
              this.notify("error", "No nodes found for the provided station_id");
            }
          })
          .catch(error => {
            this.myNodes = [];
            console.error("Error fetching node details:", error);
            this.notify("error", "There are no nodes available for this station");
          });
      },


      async enableNodeDialog(id, value, name, stationId, station_config) {

        this.myNodes = [];
        console.log('ENABLE NODE DIALOG--------------', id, value, name, stationId, station_config);
        await axios.get(`${API}/get-node-details`, {
          params: {
            station_id: stationId
          }
        })
          .then(response => {
            if (response.data && response.data.length > 0) {
              this.nodeInfoDialog = true;
              this.myNodes = response.data;
            } else {
              this.myNodes = [];
              this.nodeInfoDialog = false;
              this.notify("error", "No nodes found for the provided station_id");
            }
          })
          .catch(error => {
            this.myNodes = [];
            this.nodeInfoDialog = false;
            console.error("Error fetching node details:", error);
            this.notify("error", "There are no nodes available for this station");
          });
      },





      async nodeDetail(id, value, name, stationId, station_config, path) {

        this.selectedNode = 0;
        this.selectedApiKey = station_config.apiKey;
        this.selectedApiSecret = station_config.apiSecret;
        this.loadmyNodes(stationId);
        console.log('TOGGLE SENSOR DETAILS--------------', id, value, name, stationId, station_config, path)
        console.log(stationId, station_config.apiKey, station_config.apiSecret)
        this.davisNodes = [];
        try {
          const response = await axios.post(`${API}/getNodes`, {
            id: id,
            stationId: stationId,
            apiKey: station_config.apiKey,
            secret: station_config.apiSecret
          });
          if (response.data && response.data.nodes) {
            this.nodeDetails = true;
            this.davisNodes = response.data.nodes;
          } else {
            this.davisNodes = [];
            this.nodeDetails = false;
            this.notify("error", "No nodes data found");
          }
          console.log('RESPONSE NODES', response.data.nodes)
        } catch (error) {
          console.error("Error", error);
          this.notify("error", "There are no nodes available for this station");
        }
      },

      async turnDavis(id, value, name, stationId, station_config, path) {
        console.log('TURN DAVIS--------------', id, value, name, stationId, station_config, path)
        try {
          const response = await axios.post(`${API}/turnDavis`, {
            id: id,
          });

          if (parseInt(value) == 0) {
            SetInterval.clear(`davis${id}`);
            this.notify("success", `Stopped ${name}`);
            this.$emit("davis-turned", false);
          } else {
            SetInterval.clear(`davis${id}`);
            this.getWeatherDataDavis(stationId, JSON.stringify(station_config), id, name, path);
            SetInterval.start(
              () => {
                this.getWeatherDataDavis(stationId, JSON.stringify(station_config), id, name, path);
              },
              this.sampleTime,
              `davis${id}`
            );
            this.notify("success", `Started ${name}`);
            this.$emit("davis-turned", true);
          }
          this.getFarms();
        } catch (error) {
          console.error("Error", error);
          this.notify("error", "Failed to fetch farms");
        }
      },

      async getFarms() {
        this.farms = [];
        try {
          const response = await axios.get(
            `${API}/getFarmsByOwnerSingle/${this.user.id}/${this.itemData.id}`
          );
          this.farms = response.data;
          this.sampleTime = parseInt(this.farms[0].sampleTime * 60000);

          this.unviersalStations = this.farms.flatMap(farm =>
            farm.stations.filter(station => station.sensor_type === 1)
          );

          console.log('GET FARS: ', this.farms, "sampletime", this.sampleTime, "stations", this.unviersalStations)
        } catch (error) {
          console.error("Error", error);
          this.notify("error", "Failed to fetch farms");
        }
      },

    },
  };
</script>